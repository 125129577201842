import React, { useState } from 'react';
import { RadioGroup, Radio, Button, FormControl, FormLabel, Input, Typography, Box, Checkbox, FormHelperText, Link } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import OrderService from './OrderService';
import contentData from './content.json';

const Quiz = ({ questions }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    const [userInfo, setUserInfo] = useState({ name: '', phone: '', politics: false });
    const [isQuizComplete, setIsQuizComplete] = useState(false);

    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate('/thank_you');
    };

    const handleAnswerChange = (answer) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questions[currentQuestionIndex].id]: answer,
        }));

        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setIsQuizComplete(true);
        }
    };

    const handleNextQuestion = () => {
        if (!answers[questions[currentQuestionIndex].id]) {
            return;
        }

        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setIsQuizComplete(true);
        }
    };

    const handleUserInfoChange = (e) => {
        const { name, value, type, checked } = e.target;

        const newValue = type === 'checkbox' ? checked : value;

        setUserInfo((prevInfo) => ({
            ...prevInfo,
            [name]: newValue,
        }));
    };

    const handleSubmit = async () => {
        if (userInfo.name.length === 0 || userInfo.phone.length === 0 || (contentData.needsPolicy && !userInfo.politics)) {
            return;
        }

        const formattedAnswers = questions.map((question) => {
            const answer = answers[question.id] || 'Не выбрано';
            return `${question.question} — ${answer}`;
        }).join('. ');

        await OrderService.createOrder(userInfo.phone, userInfo.name, formattedAnswers);

        handleRedirect();
    };

    // Если массив вопросов пуст или квиз завершен, показываем форму ввода данных пользователя
    if (questions.length === 0 || isQuizComplete) {
        return (
            <div>
                <Typography level="h4">Заполните свои данные</Typography>
                <Input
                    placeholder="Имя.."
                    name="name"
                    value={userInfo.name}
                    onChange={handleUserInfoChange}
                    style={{ marginTop: '14px', borderRadius: '250px', padding: 8 }}
                />
                <Input
                    placeholder="Номер телефона..."
                    name="phone"
                    value={userInfo.phone}
                    onChange={handleUserInfoChange}
                    style={{ marginTop: '14px', borderRadius: '250px', padding: 8 }}
                />
                {contentData.needsPolicy && (
                    <FormControl size="sm" sx={{ marginTop: '14px' }}>
                        <Checkbox
                            name='politics'
                            checked={userInfo.politics}
                            onChange={handleUserInfoChange}
                            label={
                                <React.Fragment>
                                    Я прочитал и согласен с{' '}
                                    <Typography sx={{ fontWeight: 'md' }}>политикой конфиденциальности</Typography>.
                                </React.Fragment>
                            }
                        />
                        <FormHelperText>
                            <Typography level="body-sm">
                                Прочитайте нашу <Link href="https://citrustele.by/documents/politics.html">политику конфиденциальности</Link>.
                            </Typography>
                        </FormHelperText>
                    </FormControl>
                )}
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 14,
                        left: 0,
                        right: 0,
                        marginLeft: 4,
                        marginRight: 4,
                        textAlign: 'center'
                    }}
                >
                    <Button onClick={handleSubmit} sx={{
                        marginTop: '20px',
                        width: '100%',
                        borderRadius: '250px',
                        fontSize: 22,
                        px: 6,
                        py: 2,
                        textAlign: 'center'
                    }}>
                        Отправить
                    </Button>
                </Box>
            </div>
        );
    }

    const currentQuestion = questions[currentQuestionIndex];

    return (
        <div style={{ textAlign: 'left', padding: 4 }}>
            <Typography level="h3" sx={{ mb: 2 }}>{currentQuestion.question}</Typography>
            <FormControl>
                <RadioGroup
                    value={answers[currentQuestion.id] || ''}
                    onChange={(e) => handleAnswerChange(e.target.value)}
                >
                    {currentQuestion.options.map((option, index) => (
                        <Radio
                            key={index}
                            value={option}
                            label={option}
                            variant="outlined"
                        />
                    ))}
                </RadioGroup>
            </FormControl>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 14,
                    left: 0,
                    right: 0,
                    marginLeft: 4,
                    marginRight: 4,
                    textAlign: 'center'
                }}
            >
                <Button
                    onClick={handleNextQuestion}
                    sx={{
                        marginTop: '20px',
                        width: '100%',
                        borderRadius: '250px',
                        fontSize: 22,
                        px: 6,
                        py: 2,
                        textAlign: 'center'
                    }}
                >
                    Далее
                </Button>
            </Box>
        </div>
    );
};

export default Quiz;
